<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="(item.active && 'active') || (item.finish && 'navtop-canclick')"
                  @click="item.finish && stepwh_signup < 8 && changeStep(item.linkItems)"
                >
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box">
              <template v-for="(element, index) in photoRoomTypes">
                <div
                  :key="element._id"
                  class="form-box w-630"
                  :class="index > 0 && 'mt-md-4'"
                >
                  <div class="form-group">
                    <h3 v-if="index == 0" class="title-md mb-2">
                      {{ $t("SignupStep5.title") }}
                    </h3>
                    <label class="light f20">{{ element.typename }}</label>
                  </div>

                  <vue-dropzone
                    ref="myVueDropzone"
                    :id="'mydz' + element._id"
                    :options="element.imageOptions"
                    :useCustomSlot="true"
                    v-on:vdropzone-file-added="vfileAdded(element, index, ...arguments)"
                    v-on:vdropzone-success="dzUploadSuccess(element, index, ...arguments)"
                    v-on:vdropzone-removed-file="
                      dzRemoveFile(element, index, ...arguments)
                    "
                    :duplicateCheck="true"
                    class="my-0 py-0"
                  >
                    <div class="dropzone-custom-content">
                      <div class="form-image-upload">
                        <div class="inner">
                          <h4>{{ $t("SignupStep5.select_or_drag_photos") }}</h4>
                          <p>{{ $t("SignupStep5.or") }}</p>
                          <button class="btn rounded-10" type="button">
                            <span>{{ $t("SignupStep5.add_photos") }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </vue-dropzone>

                  <div v-if="element.imageItems.length > 0" class="form-image-preview">
                    <div class="row">
                      <template v-for="image in element.imageItems">
                        <div :key="image.name" class="col-6">
                          <div class="image-preview">
                            <label
                              v-if="image.name == primaryPictures"
                              class="tag-primary"
                              @click="checkBeforeRemove(element, image)"
                              >{{ $t("SignupStep5.primary") }}</label
                            >
                            <button
                              class="btn btn-remove"
                              type="button"
                              @click="checkBeforeRemove(element, image)"
                            ></button>
                            <div
                              class="image"
                              @click="setNewPrimary(element, image)"
                              :style="'background-image:url(' + image.src + ');'"
                            ></div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="form-buttons">
            <div
              class="pa-0 d-flex flex-row align-center justify-space-between flex-100per"
            >
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                type="button"
                class="btn xs gl-bt hover_btn mx-0"
                style="text-transform: capitalize !important"
                :disabled="!cangonextstep"
                @click="nextstep(6)"
              >
                <span>{{ $t("SignupStep1.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import { format } from "date-fns";
import _ from "lodash";

export default {
  name: "PicturesScreen",
  metaInfo: {
    title: "Gallery",
    titleTemplate: "%s | Aigent",
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    stepwh_signup: 5,
    photoRoomTypes: [],
    minImageWidth: 320,
    minImageHeight: 320,
    maxImageWidth: 1920,
    maxImageHeight: 1080,
    dropzoneOptions: {
      url: process.env.VUE_APP_AIGENT_API + "/dropzoneOptions",
      thumbnailWidth: 200,
      addRemoveLinks: false,
      maxFilesize: 1,
      maxFileSizeInMB: 1,
      maxFiles: 5,
      acceptedFiles: ".jpg, .jpeg, .png",
      // acceptedFiles: "image/*",
      init() {
        this.on("thumbnail", (file) => {
          if (file.width > this.maxImageWidth || file.height > this.maxImageHeight) {
            swal(
              "Image size is too large",
              "Image size should be no larger than 1920x1080 pixels",
              "warning",
              {
                button: false,
                timer: 3000,
              }
            );
          } else if (file.size > 1024 * 1024 * 1 /*1MB*/) {
            swal(
              "Image size is too large",
              "Image size should not exceed 1 MB",
              "warning",
              {
                button: false,
                timer: 3000,
              }
            );
          }
        });
      },
      complete: function (file) {
        this.removeFile(file);
      },
    },
    itemsRoomType: [],
    propertyID: "",
    cangonextstep: false,
    itemsStep: [],
    pathrightphoto: "",
    itemsPhoto: [],
    itemsPhotoHave: [],
    userToken: "",
    primaryPictures: "",
    now_upload_picture: {},
    fileAdded: [],
    fileSuccessAdded: [],
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    if (self.userToken && self.propertyID) {
      self.initStepData();
      // self.initRoomTypes()
      self.initAllPropertyData();
    } else {
      self.gotosignin();
    }
  },
  mounted() {
    EventBus.$on("sendDefaultRoomType", this.initDefaultRoomtype);
    EventBus.$on("refreshRightPhoto", this.initStepData);
    this.colorHover();
  },
  watch: {
    photoRoomTypes() {
      const self = this;
      // console.log('photoRoomTypes: ', self.photoRoomTypes)
      self.initSetPictures();
    },
    itemsPhotoHave() {
      const self = this;
      // console.log('itemsPhotoHave: ', self.itemsPhotoHave)
      self.initSetPictures();
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    async initStepData() {
      const self = this;
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // temp = res?.data?.result[0];
          res?.data?.result?.map((el) => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          // console.log(self.keepUserID)
          if (res?.data?.result?.length) {
            self.primaryPictures = res?.data?.result[0]?.cover_picture || "";
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        self.setAlbumPictures(temp.rooms);
        self.getStepData(self.itemsStep);
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      // console.log(items)
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step5-${tempLG}.gif`;
    },
    setAlbumPictures(roomNameData) {
      const self = this;
      // console.log('roomNameData: ', roomNameData)
      self.itemsPhoto = [];
      var item = [];
      var tempName = "";
      EventBus.$emit("loadingPage");
      self.itemsPhoto.push({
        _id: "indoorandoutdoor",
        name: self.$t("SignupStep5.indoorandoutdoor") || "",
        typename: self.$t("SignupStep5.indoorandoutdoor") || "",
        imageKey: "indoorandoutdoor",
        imageItems: [],
        imageOptions: self.dropzoneOptions,
        primary_picture: "",
      });
      roomNameData?.map((el) => {
        tempName = el._id;
        const temp = self?.itemsPhoto?.find((p) => p._id == el._id);
        if (!temp?._id) {
          self.itemsPhoto.push({
            _id: el._id,
            name: el?.room_name?.value || "",
            typename: el?.room_name?.value || "",
            imageKey: tempName,
            imageItems: [],
            imageOptions: self.dropzoneOptions,
            primary_picture: "",
          });
        }
      });
      self.photoRoomTypes = self.itemsPhoto;
    },
    async initAllPropertyData() {
      const self = this;
      self.fileAdded = [];
      self.fileSuccessAdded = [];
      var temp = {};
      var tempCategory = [];

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          self.primaryPictures = res?.data?.result[0]?.cover_picture || "";
          if (temp?.pictures?.length) {
            var groupPictures = _.groupBy(temp?.pictures, "category");
            tempCategory = Object.keys(groupPictures).map((key) => {
              return {
                category: key,
                pictures: groupPictures[key].map((el) => el.picture),
              };
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        // self.photoRoomTypes = tempRoomType
        EventBus.$emit("loadingPage");
        self.itemsPhotoHave = tempCategory;
        // console.log(self.photoRoomTypes)
      }
    },
    async initArea() {
      const self = this;
      var item = [];
      var itemPictures = [];
      var tempName = "";
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/area?lang=${
              localStorage.getItem("language") || self.$store.state.language
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          item = res?.data?.result || [];
          item?.map((el) => {
            tempName = el._id;
            itemPictures.push({
              _id: el._id,
              name: el?.value || "",
              typename: el?.value || "",
              imageKey: tempName,
              imageItems: [],
              imageOptions: self.dropzoneOptions,
            });
          });
          self.itemsPhoto = itemPictures;
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        // self.initRoomTypes()
      }
    },
    async initRoomTypes() {
      const self = this;
      self.itemsPhoto = [];
      var item = [];
      var tempName = "";
      EventBus.$emit("loadingPage");
      self.itemsPhoto.push({
        _id: "indoorandoutdoor",
        name: self.$t("SignupStep5.indoorandoutdoor") || "",
        typename: self.$t("SignupStep5.indoorandoutdoor") || "",
        imageKey: "indoorandoutdoor",
        imageItems: [],
        imageOptions: self.dropzoneOptions,
        primary_picture: "",
      });
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/room_type?lang=${
              localStorage.getItem("language") || self.$store.state.language
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          item = res.data.result;
          item?.map((el) => {
            tempName = el._id;
            const temp = self?.itemsPhoto?.find((p) => p._id == el._id);
            if (!temp?._id) {
              self.itemsPhoto.push({
                _id: el._id,
                name: el?.value || "",
                typename: el?.value || "",
                imageKey: tempName,
                imageItems: [],
                imageOptions: self.dropzoneOptions,
                primary_picture: "",
              });
            }
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.photoRoomTypes = self.itemsPhoto;
      }
    },
    vfileAdded(element, index, args) {
      console.log("vfileAdded: ", element, index, args);
      const self = this;
      // var new_file = null;
      self.fileAdded.push(args);

      // if (args) {
      //   new_file = new File(
      //     [args],
      //     `${element.imageKey}_${args.name}`,
      //     { type: "image/png" }
      //   );

      //   console.log(args, args.name,new_file)

      //   var formData = new FormData();
      //   formData.append(`${element.imageKey}`, new_file);
      //   const tempPhoto = self?.photoRoomTypes?.find(
      //     (p) => p.category == "indoorandoutdoor"
      //   );
      //   if (self.primaryPictures == "" && tempPhoto?.imageItems?.length < 1) {
      //     const obj = {
      //       cover_picture: new_file.name,
      //     };
      //     self.confirmSetPrimary(obj);
      //   }

      //   self.updatePictures(new_file, element.imageKey, formData);
      // }
    },
    dzUploadSuccess(element, index, args) {
      const self = this;
      self.fileSuccessAdded.push(args);
      // console.log("dzUploadSuccess: ", element, index, args);
      if (args)
        new_file = new File(
          [args],
          `${element.imageKey}_${format(new Date(), "ddMMyyyyHHmmss")}.png`,
          { type: "image/png" }
        );
      self.photoRoomTypes[index].imageItems.push(args);
      // console.log(self.photoRoomTypes, self.now_upload_picture, self.fileAdded);
      if (self.fileSuccessAdded.length == self.fileAdded.length) {
        // setTimeout(() => {
        // console.log("111")
        // self.initAllPropertyData();
        // }, 1000);

        const tempPhoto = self?.photoRoomTypes?.find(
          (p) => p.category == "indoorandoutdoor"
        );
        if (self.primaryPictures == "" && tempPhoto?.imageItems?.length < 1) {
          const obj = {
            cover_picture: new_file.name,
          };
          self.confirmSetPrimary(obj);
        }
        // self.fileSuccessAdded.forEach((ele, i) => {
        //   // if (i == (self.fileAdded.length-1)) self.initAllPropertyData();
        // var new_file = null;
        //   new_file = new File(
        //     [ele],
        //     `${element.imageKey}_${ele.name.split(".")[0]}.png`,
        //     {
        //       type: "image/png",
        //     }
        //   );
        //   // console.log(ele, i, new_file, element.imageKey);
        //   console.log("1111");
        //   var formData = new FormData();
        //   formData.append(`${element.imageKey}`, new_file);
        //   self.updatePictures(new_file, element.imageKey, i, formData);
        // });

        var i = 0,
          len = self.fileSuccessAdded.length,
          formData = new FormData();
        while (i < len) {
          var new_file = null;
          new_file = new File(
            [self.fileSuccessAdded[i]],
            `${element.imageKey}_${self.fileSuccessAdded[i].name.split(".")[0]}.png`,
            {
              type: "image/png",
            }
          );
          formData.append(`${element.imageKey}`, new_file);
          i++;
        }

        self.updatePictures(new_file, element.imageKey, i, formData);
      }
    },
    dzRemoveFile() {
      // console.log('Remove File: ', file, error, xhr)
    },
    initSetPictures() {
      const self = this;
      var tempRoomType = [];
      var num = 0;
      // console.log("photoRoomTypes: ", self.photoRoomTypes);
      // console.log("itemsPhotoHave: ", self.itemsPhotoHave);
      tempRoomType = self.photoRoomTypes;
      self.cangonextstep = false;
      if (self?.itemsPhotoHave?.length) {
        var tmpImage = null;
        tempRoomType.map((el) => {
          el.imageItems = [];
          tmpImage = self.itemsPhotoHave?.find((o) => o.category === el.imageKey);
          if (tmpImage?.category == "indoorandoutdoor") {
            if (tmpImage?.pictures?.length) self.cangonextstep = true;
            else self.cangonextstep = false;
          }
          // console.log(tmpImage, "picture: ", tmpImage?.picture);
          if (tmpImage?.pictures?.length) {
            // self.cangonextstep = true
            num = num + 1;
            tmpImage?.pictures.map((ele) => {
              el.imageItems.push({
                _id: ele,
                name: ele,
                src: `${process.env.VUE_APP_PIC_API}/${ele}`,
              });
            });
          }
          el.category = tmpImage?.category || "";
          el.primary_picture = tmpImage?.primary_picture || "";
        });
        // console.log(num)
        // if (num == tempRoomType.length) self.cangonextstep = true
        self.photoRoomTypes = tempRoomType;
      }
    },
    async updatePictures(file, key, i, obj) {
      console.log("updatePictures: ", file, key, i, obj);
      const self = this;
      // var formData = new FormData();
      // formData.append(`${key}`, file);
      // const tempPhoto = self?.photoRoomTypes?.find(
      //   (p) => p.category == "indoorandoutdoor"
      // );
      // // console.log(self.primaryPictures, tempPhoto?.imageItems?.length)
      // if (self.primaryPictures == "" && tempPhoto?.imageItems?.length < 1) {
      //   const obj = {
      //     cover_picture: file.name,
      //   };
      //   self.confirmSetPrimary(obj);
      // }
      EventBus.$emit("loadingtillend");
      self.now_upload_picture = {
        file,
        key,
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step5?property_id=${self.propertyID}&next=${0}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          // if (i == self.fileSuccessAdded.length - 1)
          //   // setTimeout(() => {
          //   self.initAllPropertyData();
          // // }, 1500);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAllPropertyData();
        // if (i == self.fileSuccessAdded.length - 1) console.log(i);
        // // setTimeout(() => {
        // // self.initAllPropertyData();
        // // }, 1500);
      }
    },
    checkBeforeRemove(data, item) {
      const self = this;
      if (self.primaryPictures == item.name) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SignupStep5.cantremoveprimary"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        swal(self.$t("Alert.qtneedtoremove"), {
          dangerMode: true,
          buttons: {
            cancel: self.$t("Alert.btn_no"),
            confirm: {
              text: self.$t("Alert.btn_yes"),
              value: "confirm",
            },
          },
        }).then((value) => {
          if (value === "confirm") {
            self.removePicture(item);
          }
        });
      }
    },
    async removePicture(file) {
      const self = this;
      EventBus.$emit("loadingtillend");
      if (self.primaryPictures !== file.name) {
        try {
          const res = await self.axios.delete(
            process.env.VUE_APP_API +
              `/property/picture?property_id=${self.propertyID}&picture_name=${file.name}`,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          if (res.status == 200) {
            self.initAllPropertyData();
            EventBus.$emit("endloading");
          } else {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.warn_title"),
              self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message || "Please try again",
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SignupStep5.cantremoveprimary"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    setNewPrimary(element, image) {
      const self = this;
      const obj = {
        cover_picture: image.name,
      };
      swal(self.$t("SignupStep5.changetoprimary"), {
        buttons: {
          cancel: self.$t("Alert.btn_no"),
          confirm: {
            text: self.$t("Alert.btn_yes"),
            value: "confirm",
          },
        },
      }).then((value) => {
        if (value === "confirm") {
          self.confirmSetPrimary(obj);
        }
      });
    },
    async confirmSetPrimary(obj) {
      const self = this;
      // console.log(obj)
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/cover_picture?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.initAllPropertyData();
          EventBus.$emit("loadingPage");
        } else {
          swal("Warning", res?.message || "Please try again", "warning", {
            button: false,
            timer: 3000,
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      }
    },
    async nextstep(num) {
      const self = this;
      var linkItems = {
        link: `signup/policy/${self.propertyID}`,
        name: "SignupPolicyScreen",
        params: { propertyId: self.propertyID },
      };
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step5?property_id=${self.propertyID}&next=${1}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async changeStep(linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step5?property_id=${self.propertyID}&next=${0}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    alertDimensionLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolarge"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    alertSizeLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolargesize"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/facilities/${self.propertyID}`,
        name: "SignupFacilitiesScreen",
        params: { propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", item);
    },
  },
};
</script>

<style scoped>
@import "../../../styles/DragAndDrop.scss";
@import "../../../styles/bootstrap/global.css";

.image-div {
  display: flex;
  margin: 25px;
}
.item-image {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}
.remove-btn {
  position: absolute;
  right: 28px;
  top: 35px;
  background: rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 20px;
  z-index: 999;
}

.vue-dropzone {
  /* border: 2px solid #e5e5e5; */
  border: none !important;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: 0.2s linear;
}

.vue-dropzone:hover {
  background-color: #f6f6f6;
}

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  /* padding: 20px 20px; */
  padding: 0 !important;
}

.dropzone .dz-message {
  text-align: center;
  /* margin: 2em 0; */
  margin: 0 !important;
}

div.dz-message {
  text-align: center;
  margin: 0 !important;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  height: fit-content !important;
}
</style>

<style>
.dz-message {
  margin: 0 !important;
}

.swal-button--confirm {
  background-color: #ea084a !important;
  border: 1px solid #ea084a !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
